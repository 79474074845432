<!-- 组件说明 -->
<template>
    <div class="home_work">
        <div class="opt_path mb20">
            <span @click="$router.push({ path: `/homework` })">插花作业</span>
            <span>编辑作业</span>
        </div>
        <div class="form-box">
            <div class="form-item">
                <div class="form-lab"><span>*</span>参与对象</div>
                <div class="form-val">
                    <el-checkbox v-model="item.checked" v-for="(item, index) in tagList" :key="index">{{ item.name
                    }}</el-checkbox>
                </div>
            </div>
            <div class="form-item">
                <div class="form-lab">提交要求</div>
                <div class="form-val">
                    <el-switch v-model="query.hasDesc" active-color="#13ce66"></el-switch>
                    <span class="ml10">{{ query.hasDesc ? "已开启，提交作业时，须填写描述" : "已关闭，提交作业时，不用填写描述" }}</span>
                </div>
            </div>
            <div class="form-item">
                <div class="form-lab">关联课件</div>
                <div class="form-val">
                    <el-select v-model="query.coursewareId" @change="teachingChange" placeholder="请选择课件" style="width: 50%">
                        <el-option v-for="item in teachingList" :key="item.id" :label="item.title" :value="item.id">
                            {{ item.title }}
                        </el-option>
                    </el-select>
                </div>
            </div>
            <div class="form-item">
                <div class="form-lab"><span>*</span>作业名称</div>
                <div class="form-val">
                    <el-input v-model="query.schoolworkName" placeholder="请填写插花作业名称" maxlength="50" show-word-limit />
                </div>
            </div>
            <div class="form-item">
                <div class="form-lab"><span>*</span>作业要求</div>
                <div class="form-val">
                    <el-input :autosize="{ minRows: 4, maxRows: 6 }" v-model="query.schoolworkRequest" type="textarea" placeholder="请填写作业要求" maxlength="500" show-word-limit />
                </div>
            </div>
            <div class="form-item">
                <div class="form-lab"><span>*</span>可用素材</div>
                <div class="form-val">
                    <div>
                        <div v-if="!bxscChecked" class="mb-20">
                            <el-radio-group v-model="tabIndex">
                                <el-radio-button :label="0">花材</el-radio-button>
                                <el-radio-button :label="1">器皿</el-radio-button>
                                <el-radio-button :label="2">剑山</el-radio-button>
                            </el-radio-group>

                            <div class="mt-20">
                                <div v-for="(item, index) in query.schoolworkUseMaterials[tabIndex].useMaterials" :key="index" class="material_part c_list">
                                    <div class="img_card">
                                        <div class="close">
                                            <span>限次：
                                                <el-input v-model="item.count" @blur="checkNumber(item)" style="flex: 1;height: 20px;"></el-input>
                                            </span>
                                            <i class="el-icon-close" @click="delFlower(index)"></i>
                                        </div>
                                        <img :src="item.coverImage | imgFormat" alt="" />
                                    </div>
                                    <div style="margin-top: 5px">
                                        {{ item.materialName }}
                                    </div>
                                </div>
                                <div class="ad_btn" @click="openCheckFlowerDialog()">
                                    <i class="el-icon-plus"></i>
                                </div>
                            </div>
                        </div>
                        <!-- <el-checkbox v-model="bxscChecked">不限花材</el-checkbox> -->
                    </div>
                </div>
            </div>
            <div class="form-item">
                <div class="form-lab"><span>*</span>提交时间</div>
                <div class="form-val">
                    <el-date-picker v-model="query.submitEndTime" type="datetime" placeholder="选择日期时间" value-format="yyyy-MM-dd HH:mm:ss" :picker-options="pickerOptions"></el-date-picker>
                </div>
            </div>
            <div class="form-item">
                <div class="form-lab"><span>*</span>超时提交</div>
                <div class="form-val">
                    <el-switch v-model="query.hasTimeout" active-color="#13ce66"></el-switch>
                    <span class="ml10">{{ query.hasTimeout ? "已开启，超过提交时间也能提交作业" : "已关闭，超过提交时间，不允许提交作业" }}</span>
                </div>
            </div>

            <div>
                <el-button size="medium" type="success" @click="commitTask('send')">
                    确定并发布
                </el-button>
                <el-button size="medium" @click="commitTask()">
                    保存暂不发布
                </el-button>
                <el-button size="medium" @click="$router.back()">
                    取消
                </el-button>
            </div>
        </div>

        <CheckFlowerDialog ref="checkFlowerDialog" @change="changeFlower" />
    </div>
</template>

<script>
import CheckFlowerDialog from '../../../components/CheckFlowerDialog.vue';

export default {
    components: { CheckFlowerDialog },
    data () {
        return {
            tagList: [],
            teachingList: [],
            query: {
                id: "",
                groupIds: [], //班级ids
                hasDesc: false, //是否必须填写描述
                coursewareId: "", // 关联课件id
                coursewareTitle: "",
                schoolworkName: "", // 作业名称
                schoolworkRequest: "", // 作业要求
                submitEndTime: "", // 提交截止时间 yyyy-MM-dd HH:mm:ss
                hasTimeout: false, // 是否允许超时提交
                schoolworkUseMaterials: [
                    {
                        type: 1,
                        useMaterials: [],
                    },
                    {
                        type: 2,
                        useMaterials: [],
                    },
                    {
                        type: 3,
                        useMaterials: [],
                    },
                ],

                schoolChecked: false, //提交要球
            },
            bxscChecked: false, // 不限素材
            endTime: "",

            tabIndex: 0,
            flowerArr: {
                0: [],
                1: [],
                2: [],
            },
            pickerOptions: {
                disabledDate (time) {
                    return time.getTime() < (Date.now() - 3600 * 1000 * 24)
                },
            }
        };
    },
    mounted () {
        this.$set(this.query, 'id', this.$route.query.id)
        this.getTagList();
        this.getTeachingList();
    },
    methods: {
        checkNumber (item) {
            // 判断输入的值是否为正整数
            const reg = /^[1-9]\d*$/;
            if (!reg.test(item.count)) {
                // 不是正整数，则将输入框的值设为1
                item.count = 1;
            }
        },
        getTagList () {
            this.$ajax.post("/user/group/getList", {}).then(res => {
                res.data.map(item => {
                    item.checked = false
                })

                this.tagList = res.data.filter(item => item.stuCount);
                if (this.query.id) {
                    this.getTaskDetail();
                }
            });
        },
        getTaskDetail () {
            const { tagList } = this;
            this.$ajax
                .get("/schoolwork/getById", { params: { id: this.query.id } })
                .then(res => {
                    const { groupIds, schoolworkUseMaterials } = res.data;

                    tagList.map(
                        item => groupIds.includes(item.id) && (item.checked = true)
                    );

                    const checkCount = schoolworkUseMaterials.reduce(
                        (sum, item) => (sum += item.useMaterials.length),
                        0
                    );
                    console.log(checkCount);

                    this.query = res.data;
                    this.tagList = tagList;
                    this.bxscChecked = !checkCount;
                });
        },
        getTeachingList () {
            this.$ajax.post("/coursewareOrganization/pageList", {
                publishStatus: 1,
                title: "", // 作业名称
                addType: "",

                startTime: "",
                endTime: "",
                pageIndex: 1,
                pageSize: 999,
            }).then(res => {
                this.teachingList = res.list
            });
        },
        delFlower (index) {
            const { tabIndex, query } = this;

            query.schoolworkUseMaterials[tabIndex].useMaterials.splice(index, 1);
            this.query = query;
        },
        teachingChange (id) {
            let item = this.teachingList.find(item => item.id == id);
            this.$set(this.query, 'coursewareTitle', item.title);
        },
        openCheckFlowerDialog () {
            const { query, tabIndex } = this;


            let checkedArr = [];
            query.schoolworkUseMaterials[tabIndex].useMaterials.map(item => {
                checkedArr.push({
                    id: item.materialId,
                    name: item.materialName,
                    count: item.count,
                    coverImage: item.coverImage,
                });
            });

            this.$refs.checkFlowerDialog.showDialog(query.schoolworkUseMaterials[tabIndex].type, checkedArr)

        },
        // 选择回调
        changeFlower (checkedArr) {
            const { query, tabIndex } = this;
            let arr = [];

            checkedArr.map(item => {
                arr.push({
                    materialId: item.id,
                    materialName: item.name,
                    count: item.count || 1,
                    coverImage: item.coverImage,
                });
            });

            query.schoolworkUseMaterials[tabIndex].useMaterials = arr;

            this.query = query
        },
        commitTask (type) {
            let { tagList, query, bxscChecked } = this;

            query.groupIds = tagList
                .filter(item => item.checked && item)
                .map(v => v.id);

            if (bxscChecked) {
                query.schoolworkUseMaterials = [
                    { type: 1, useMaterials: [] },
                    { type: 2, useMaterials: [] },
                    { type: 3, useMaterials: [] },
                ];
            }

            if (!query.groupIds.length) {
                this.$message.warning("请选择发布范围");
                return
            }
            if (!query.schoolworkName) {
                this.$message.warning("请填写作业名称");
                return
            }
            if (!query.schoolworkRequest) {
                this.$message.warning("请填写作业要求");
                return
            }
            if (!query.schoolworkUseMaterials[0].useMaterials.length) {
                this.$message.warning("请至少选择一种花材");
                return
            }
            if (!query.submitEndTime) {
                this.$message.warning("请填写作业结束时间！");
                return
            }
            if (new Date(query.submitEndTime) < Date.now()) {
                this.$message.warning("作业结束时间必须大于当前时间！");
                return
            }

            this.$ajax.post("/schoolwork/save", query).then(res => {
                if (type == "send") {
                    this.$ajax.get("/schoolwork/publish?id=" + res.data).then(res => {
                        this.$router.back()
                    });
                } else {
                    this.$router.back()
                }
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.form-item {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 20px;

    .form-lab {
        width: 100px;
        font-size: 14px;
        line-height: 36px;
        text-align: right;
        margin-right: 20px;

        >span {
            color: red;
            margin-right: 6px;
        }
    }

    .form-val {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        flex: 1;

        .img_card {
            width: 110px;
            height: 110px;
            position: relative;
            border: 1px solid #cccccc;
            margin: auto;

            .check {
                position: absolute;
                width: 20px;
                height: 20px;
                border: 1px solid #cccccc;
                border-right: none;
                border-top: none;
                right: 0;
                top: 0;
            }

            .close {
                position: absolute;
                width: 110px;
                height: 20px;
                background-color: #0000001e;
                right: 0;
                bottom: 0;
                cursor: pointer;
                display: flex;
                justify-content: space-between;

                span {
                    flex: 1;
                    font-size: 12px;
                    line-height: 20px;
                    text-align: left;

                    display: flex;
                    justify-content: flex-start;

                    ::v-deep .el-input__inner {
                        height: 20px;
                        line-height: 20px;
                    }
                }

                i {
                    font-weight: bold;
                    font-size: 12px;
                    text-align: center;
                    line-height: 20px;
                    color: #000;
                    padding-right: 4px;
                    padding-left: 4px;
                    border-left: 1px solid #999;
                }
            }

            .a_show {
                display: none;
                line-height: 20px;
            }

            .active {
                background-color: #8bb229;
                color: #ffffff;

                .a_show {
                    display: block;
                }
            }

            img {
                width: 70px;
                height: 70px;
                margin-top: 10px;
            }
        }
    }
}

.ml10 {
    margin-left: 10px;
}

.mt-20 {
    margin-top: 20px;
}

.mb-20 {
    margin-bottom: 20px;
}

.a_color {
    color: rgb(105, 191, 248);
    cursor: pointer;
}

.search_part {
    >* {
        +* {
            margin-left: 7px;
        }

        &:first-child,
        &:nth-child(2) {
            width: 120px;
        }

        &:nth-child(3) {
            width: 200px;
        }

        &:nth-child(6) {
            width: 220px;
        }
    }
}

.vert {
    vertical-align: top;
}

.home_work {
    padding: 20px 30px 0 0;

    //   padding-top: 40px;
    .select-bnt {
        display: inline-block;
        width: 86px;
        height: 32px;
        margin-right: 20px;
        cursor: pointer;
        background: #ffffff;
        // border-radius: 6px;
        font-size: 14px;
        font-weight: 400;
        line-height: 32px;
        color: #999999;
        text-align: center;

        &.is-active {
            color: #f56c0b;
            border: 1px solid #f56c0b;
        }
    }
}

.work_list {
    margin-top: 20px;
    width: 100%;
    height: auto;
    overflow: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    flex-direction: row;
}

.no_data {
    text-align: center;

    .empty {
        margin-top: 60px;
        margin-bottom: 24px;

        i {
            font-size: 130px;
            color: #999;
        }
    }

    p {
        font-size: 16px;
        color: #999999;
        margin-bottom: 139px;
    }
}

.page_part {
    height: 25px;
    margin-bottom: 10px;
    margin-top: 15px;
    padding: 10px 20px !important;
    text-align: center;
}

.ad_btn {
    display: inline-block;
    width: 110px;
    height: 110px;
    border: 1px solid #cccccc;
    cursor: pointer;
    text-align: center;

    .el-icon-plus {
        font-size: 25px;
        font-weight: bold;
        line-height: 100px;
        color: #cccccc;
    }
}

.d_class {
    .el-dialog__body {
        padding: 10px;
    }
}

.material_part {
    display: inline-block;
    width: 130px;
    height: 150px;
    line-height: 16px;
    text-align: center;
    vertical-align: top;

    &.c_list {
        width: 110px;
        margin-right: 11px;
    }
}

.t_btn {
    background: rgba(218, 218, 218, 0.39);
    border-radius: 4px;
    padding: 8px 24px;
    margin-right: 8px;
    margin-bottom: 8px;
    border: 1px solid rgba(250, 250, 250, 0.39);
    cursor: pointer;

    &:hover {
        border: 1px solid #8bb229;
        color: #8bb229;
    }

    &.active {
        border: 1px solid #8bb229;
        color: #8bb229;
    }
}

.written {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
</style>
